<template>
  <div>
    <div>
      <h1
        class="header-text pb-3"
        ref="headerEditProfile"
        aria-labelledby="header for Milestone Report page"
        data-cy="header-admin-reports-milestones"
        autofocus
      >
        Milestone Report
      </h1>
      <div class="flex">
        <div class="flex-auto">
          <div class="mb-2">Term</div>
          <select
            class="mb-2 w-1/2 rounded border-dark-gray py-1"
            v-model="term"
            :disabled="isLoading"
          >
            <option
              class="h-8"
              v-for="(term, key) in milestoneTerms"
              :key="key"
              :value="term"
            >
              {{ term.name }}
            </option>
          </select>
          <div v-if="isLoading">
            <LoadingSpinner />
          </div>
        </div>

        <div class="text-right flex-auto py-6">
          <Button
            class="hidden"
            text="Download Data"
            @click="downloadData('milestones.csv', records)"
            :disabled="isLoading"
          />
        </div>
      </div>
      <div class="box-style mb-6">
        The Milestone Report produces a significant amount of data that cannot be displayed
        on a web page. A CSV will be sent via email to you when complete for the
        current term. To choose a previous term, select one from the dropdown
        above.
      </div>
    </div>
    <div class="box-style mb-6 hidden">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.records"
          :fields="tableConfig"
          no-data-msg=""
          striped
          hover
        >
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get } from "@/composables/httpUtil";
import { downloadData } from "@/composables/downloadUtils";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "Milestones",
  components: { LoadingSpinner, DynamicTable, Button },
  data() {
    return {
      isLoading: true,
      records: [],
      term: null,
      terms: [],
      milestoneTerms: [],
      tableConfig: [
        {
          display: "First Name",
          name: "firstname",
          sortable: false,
          class: "",
        },
        {
          display: "Last Name",
          name: "lastname",
          sortable: false,
          class: "",
        },
        {
          display: "Email Address",
          name: "email",
          sortable: false,
          class: "",
        },
        {
          display: "Level",
          name: "level",
          sortable: false,
          class: "",
        },
        {
          display: "Last Approved Activity",
          name: "latest",
          sortable: false,
          class: "",
        },
        {
          display: "Total Approved DISTINCT Experiences",
          name: "distinct",
          sortable: false,
          class: "",
        },
        {
          display: "Total Approved Experiences",
          name: "exp",
          sortable: false,
          class: "",
        },
        {
          display: "Total Approved Hours",
          name: "hours",
          sortable: false,
          class: "",
        },
        {
          display: "Orientation",
          name: "orientation",
          sortable: false,
          class: "",
        },
        {
          display: "PPDP Meetings",
          name: "ppdp",
          sortable: false,
          class: "",
        },
        {
          display: "Latest PPDP Meeting Date",
          name: "ppdp_date",
          sortable: false,
          class: "",
        },
        {
          display: "Debriefings",
          name: "debrief",
          sortable: false,
          class: "",
        },
        {
          display: "Networking",
          name: "networking",
          sortable: false,
          class: "",
        },
        {
          display: "Year-End Meeting",
          name: "yearend",
          sortable: false,
          class: "",
        },
        {
          display: "Year-End Meeting Date",
          name: "yearend_date",
          sortable: false,
          class: "",
        },
        {
          display: "Points of Contact",
          name: "poc",
          sortable: false,
          class: "",
        },
        {
          display: "Mid-Year Milestone Met",
          name: "mid",
          sortable: false,
          class: "",
        },
        {
          display: "Latest Experience Date Eligible for Mid-Year Milestone",
          name: "mid_date",
          sortable: false,
          class: "",
        },
        {
          display: "Year-End Milestone Met",
          name: "eoy",
          sortable: false,
          class: "",
        },
        {
          display: "Latest Experience Date Eligible for Year-End Milestone ",
          name: "eoy_date",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.fetchLookupResource().then(
      get("admin/terms")
        .then((terms) => {
          this.terms = terms.data;
          this.term = this.lookup_current_term;
          this.milestoneTerms = this.terms.filter((term)=> term.milestones!==null);
        })
        .finally(() => {
          this.$watch(
            (vm) => vm.term,
            () => {
              this.getData();
            },
            { immediate: true }
          );
        })
    );
  },
  methods: {
    downloadData,
    getData() {
      this.isLoading = true;
      let url = `admin/reports/milestones?term=${this.term.term_id}`;
      get(url)
        .then((results) => {
          this.records = results;
        })
        .catch((error) => {
          console.log("error in catch statement", error);
          this.records = [];
        })
        .finally(() => (this.isLoading = false));
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
  },
};
</script>

<style scoped></style>
